import pdfMake from 'pdfmake';
import handlePurchase from "@/services/modules/purchase";
import pdfFonts from 'pdfmake/build/vfs_fonts';
import { toCapitalizeFirst } from '@/services/utils/global.js'
pdfMake.vfs = pdfFonts.pdfMake.vfs;

pdfMake.fonts = {
    SulaimanLipi: {
        normal: 'https://fonts.cdnfonts.com/s/14639/solaimanlipi.woff',
        bold: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Medium.ttf',
        italics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-Italic.ttf',
        bolditalics: 'https://cdnjs.cloudflare.com/ajax/libs/pdfmake/0.1.66/fonts/Roboto/Roboto-MediumItalic.ttf'
    }
}

const pdfPrinter = () => {

    const {formatCurrency, currencyToWord} = handlePurchase();
    const exportToPDF = (company, invoice, barcode = '', qrcode, userName, btnFunc, invoiceNotes = [],orderSale = false) => {
        const tableItems = invoice.has_item_detail ? invoice.general : invoice.ledgers;
        var doc = {
            pageSize: 'A4',
            pageMargins: [ 60, 100, 60, 80 ],
            header: getHeader(company),
            footer: getFooter(userName, qrcode),
            content: getContent(invoice, tableItems, barcode, btnFunc, invoiceNotes,orderSale),

            styles : {
                header: {
                    fontSize: 24,
                }
            },
            defaultStyle: {
                color: 'black',
                fontSize: 10,
                font: 'SulaimanLipi'
            },
            info: {
                title: invoice.bill_number
            }
        }

        pushIntoTable(doc, invoice, tableItems, btnFunc)
        if (btnFunc == 1){
            pushIntoTableTotal(doc, invoice, tableItems)
        }

        const pdfDocGenerator = pdfMake.createPdf(doc);
        pdfDocGenerator.open();
    }
    const formatDate = (date) => {
        var formattedDate = date.toISOString().split('T')[0];
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var amOrPm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12 || 12;
        var formattedTime = hours + ':' + (minutes < 10 ? '0' : '') + minutes + ' ' + amOrPm;
        return formatToDdMmYy(formattedDate) + ' ' + formattedTime
    }
    const formatToDdMmYy = (date) => {
        var dat = date.split('-');
        return `${dat[2]}-${dat[1]}-${dat[0]}`;
    }
    const getHeader = (company) => {
        return {
            margin: [ 60, 25, 60, 10 ],
            columns: [
                {
                    alignment: 'left',
                    image: company.logo64,
                    maxHeight: 60
                },

                {
                    alignment: 'right',
                    stack: [
                         company.name,
                         company.address ? 'Address: ' + company.address : '',
                         company.phone ? 'Phone: ' + company.phone : '',
                         company.email ? 'E-mail: ' + company.email : '',
                         company.vat_no ? 'VAT Reg No: ' + company.vat_no : '',
                    ]
                }
            ]
        }
    }
    const getContent = (invoice, tableItems, barcode, btnFunc, invoiceNotes,orderSale) => {
        return  [
            {
                text: (btnFunc == 1) ? 'INVOICE' : 'DELIVERY CHALLAN',
                style: 'header',
                alignment: 'center',
                margin: [0, 0, 20, 10],
                bold: true,
                color: 'black',
            },
            {
                margin: [0, 0, 20, 10],
                alignment: 'center',
                color: 'black',
                bold: true,
                fontSize: 12,
                text:(btnFunc === 1 && orderSale && invoice.sale_type_title && invoice.sale_type_title !== '') ?
                    '(' + invoice.sale_type_title +  ')' : '',
            },
            {
                margin: [0, 20, 0, 0],
                alignment: 'justify',
                columns: [
                    {
                        width: '50%',
                        stack: [
                            {
                                text: (btnFunc == 1) ? 'INVOICE TO' : 'SHIP TO',
                                color: 'black',
                                bold: true,

                            },

                            {
                                text: invoice.contact.name,
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: invoice.contact.full_address ? 'Address: ' + invoice.contact.full_address : '',
                                alignment: 'left',
                                width: '50%'
                            },
                            {
                                text: getProfileItemByField(invoice.contact.profile_items, 'phone') ?
                                    'Mobile No: ' + getProfileItemByField(invoice.contact.profile_items, 'phone') : ''
                            },
                            {
                                text: getProfileItemByField(invoice.contact.profile_items, 'billing_address') ?
                                      'Address: ' + getProfileItemByField(invoice.contact.profile_items, 'billing_address') : ''
                            },

                            {
                                text: `${invoice.contact.address ? 'Address: ' + invoice.contact.address : ''}`
                            },

                        ]

                    },

                    {
                        alignment: 'right',
                        stack: [
                            {
                                text: [
                                    {
                                        text: (btnFunc == 1) ? 'DATE: ' : 'CHALLAN DATE: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },

                                    {
                                        text: formatToDdMmYy(invoice.date),
                                    }
                                ]
                            },
                            (btnFunc === 1 && !orderSale && invoice.sale_type_title && invoice.sale_type_title !== '') ? {
                                text: [
                                    {
                                        text: 'Sale Type: ',
                                        bold: true,
                                        margin: [0, 0, 10, 0]
                                    },
                                    {
                                        text: invoice.sale_type_title,
                                    }
                                ]
                            } : '',
                            barcode ? {
                                text: [
                                    {
                                        text: (btnFunc == 1) ? 'INVOICE REF: ' : 'CHALLAN NO: ',
                                        bold: true,
                                        margin: [0, 0, 20, 0]
                                    },

                                    {
                                        text: invoice.bill_number
                                    }
                                ]
                            } : '',

                            barcode ? {
                                svg: barcode
                            } : '',


                            (invoice.location_name) ? {
                                text: [
                                    {
                                        text: 'SHIP FROM: ',
                                        bold: true,
                                    },
                                    {
                                        text: invoice.location_name,
                                    }
                                ],
                                margin: [0, 5, 0, 0]
                            } : ''
                        ]
                    },
                ]
            },

            {
                style: 'tableExample',
                margin: [0, 10, 0, 0],
                table: {
                    widths: getTableWidth(btnFunc, tableItems),
                    body: []
                },
                layout: {
			        fillColor: function (rowIndex,node) {
                       const lastRowIndex = node.table.body.length - 1;
                       return (rowIndex === 0 || rowIndex === lastRowIndex) ? '#f3f2f7' : null;
                    },
                    hLineWidth: function () {
                        return 0.5;
                    },
                    vLineWidth: function () {
                        return 0.5;
                    }
		        }
		    },


            {
                columns: [
                    {
                        width: '55%',
                        text: [
                            {
                                text: 'In Words: ',
                                bold: true
                            },

                            {
                                text: invoice.total_amount ? currencyToWord(invoice.total_amount) : ''
                            }
                        ]
                    },
                     
                    (btnFunc == 1) ? {
                        table: {
                            widths: ['*', '*'],
                            alignment: 'right',
                            body: formatTotals(invoice)
                        },
                        layout: {
                            hLineWidth: function () {
                                return 0.5;
                            },
                            vLineWidth: function () {
                                return 0.5;
                            }
                        }
                    } : ''
                ],
                margin: [0, 10]
            },

            invoiceNotes.length ? {
                alignment: 'left',
                stack: formatInvoiceTerms(invoiceNotes)
            } : ''

        ]
    }
    const getTableWidth = (btnFunc, tableItems) => {
        if (btnFunc != 1) {
            return  ['*', 'auto', 'auto', 'auto']
        }

        const hasFreeQuantity = tableItems.find(item => item.free_quantity > 0);
        const hasTradeDiscount = tableItems.find(item => item.discount_amount > 0);
        const hasOfferDiscount = tableItems.find(item => item.offer_discount > 0);
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);

        const rowStructure = ['auto','*', 'auto', 'auto', 'auto'];

        if (!! hasFreeQuantity) {
            rowStructure.push('auto');
        }

        if (!! hasTradeDiscount || !! hasOfferDiscount || !! hasVatAmount) {
            rowStructure.push('auto');
        }

        if (!! hasTradeDiscount) {
            rowStructure.push('auto');
        }

        if (!! hasOfferDiscount) {
            rowStructure.push('auto');
        }

        if (!! hasVatAmount) {
            rowStructure.push('auto');
        }

        return  rowStructure;

    }
    const getFooter = (userName, qrcode) =>{
        return {
            margin: [ 60, 0, 60, 0 ],
            columns: [
                {
                    columns: [
                        {
                            svg: qrcode
                        },

                        {
                            width: '90%',
                            alignment: 'left',
                            text: 'N.B: This is a system generated documents and requires no manual signature.',
                            margin: [5, 53, 0, 0],
                            fontSize: 8
                        }
                    ]
                },

                {
                    width: '20%',
                    alignment: 'right',
                    fontSize: 8,
                    margin: [0, 33, 0, 0],
                    stack: [
                        {
                            text: 'Printed by',
                            bold: true
                        },
                        {
                            text: userName
                        },
                        {
                            text: formatDate(new Date())
                        }
                    ]
                }
            ]
        }
    }

    const getProfileItemByField = (profile_items, keyValue) => {
        const item = profile_items.find(item => item.field === keyValue);
        return (item) ? item.value : '';
    }

    const formatTotals = (invoice) => {
        const paidAmount = getAdjustedBillAmount(invoice, 'paid_amount');

        const totals = [
            [{ text: 'Sub-total Amount', bold: true }, { text: formatCurrency(calculateSubtotal(invoice)), alignment: 'right'}],
            (invoice.vat_amount) ?
              [ { text: 'VAT Amount', bold: true }, {text:  formatCurrency(invoice.vat_amount), alignment: 'right' } ] :
               null,
            (invoice.vat_amount) ?
              [{ text: 'Total Amount', bold: true }, { text: formatCurrency(calculateSubtotal(invoice, true)), alignment: 'right'}] :
               null,
            (invoice.round_up_discount) ?
              [ { text: 'Discount/Adjustment', bold: true }, {text:  formatCurrency(invoice.round_up_discount), alignment: 'right' } ] : null,
            (invoice.round_up_discount) ?
                [ { text: 'Total Invoice Amount', bold: true }, { text: formatCurrency(invoice.total_amount), alignment: 'right'}] : null,
            (invoice.advance_adj_amount) ?
              [ { text: 'Already Paid/Adj.', bold: true }, {text:  formatCurrency(invoice.advance_adj_amount), alignment: 'right' } ] : null,
            (invoice.round_up_discount || (invoice.advance_adj_amount)) ?
            [ { text: 'Net Bill Amount', bold: true }, { text: formatCurrency(getAdjustedBillAmount(invoice)), alignment: 'right'}] : null,
        ].filter((item) => item !== null)

        if(! paidAmount) return totals;

        return totals.concat([
            [ { text: 'Amount Paid', bold: true }, { text: formatCurrency(paidAmount), alignment: 'right'}],
            [ { text: 'Total Payable', bold: true }, { text: formatCurrency(invoice.total_amount - invoice.paid_amount), alignment: 'right'}],
        ]);
    }

    const calculateSubtotal = (invoice, withVat = false) => {
        const roundUpDiscount = invoice.round_up_discount ?? 0;
        const totalDiscount = invoice.total_discount ?? 0;
        const discountBeforeRoundUp = totalDiscount - roundUpDiscount;
        const subTotal = invoice.subtotal_amount - discountBeforeRoundUp;

        if (withVat) {
            return subTotal + invoice.vat_amount;
        }

        return subTotal;
    }

    const getAdjustedBillAmount = (invoice, key = 'total_amount' ) => {
        if (invoice.advance_adj_amount) {
            return invoice[key] - invoice.advance_adj_amount;
        }

        return invoice[key]
    }

    const formatInvoiceTerms = (terms) => {
        return  terms.map((item) => {
            return {
                text: `${item.full_desc}`
            }
        })
    }

    let srNumber = 0;
    const pushIntoTable = (doc, invoice, tableItems, btnFunc) => {
        srNumber = 0;
        const hasFreeQuantity = tableItems.find(item => item.free_quantity > 0);
        const hasTradeDiscount = tableItems.find(item => item.discount_amount > 0);
        const hasOfferDiscount = tableItems.find(item => item.offer_discount > 0);
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);

        if (tableItems.length > 0) {
            const tableHeader = getTableHeader(btnFunc, tableItems);
            doc.content[3].table.body.push(tableHeader);
        }

        tableItems.forEach(item => {
            const rowData = getRowData(invoice, item, btnFunc, !!hasFreeQuantity, !!hasTradeDiscount, !!hasOfferDiscount, !!hasVatAmount);
            return doc.content[3].table.body.push(rowData);
        });
    }
    const pushIntoTableTotal = (doc, invoice, tableItems) => {
        const hasFreeQuantity = tableItems.find(item => item.free_quantity > 0);
        const hasTradeDiscount = tableItems.find(item => item.discount_amount > 0);
        const hasOfferDiscount = tableItems.find(item => item.offer_discount > 0);
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);

        const data = [
            { text:  '' },
            {
              text:  'Total'
            },
            { text:  formatCurrency(invoice.total_quantity), alignment: 'right' },
            { text: '', alignment: 'right' },
            {
                text: formatCurrency(invoice.total_sale_amount),
                alignment: 'right'
            },
        ]

        if (hasFreeQuantity) {
            data.push({ text:  formatCurrency(invoice.total_free_quantity), alignment: 'right' })
        }

        if (hasTradeDiscount && hasOfferDiscount && hasVatAmount) {
            data.splice(5, 0, ...[
                { text: formatCurrency(invoice.total_amount), alignment: 'right' },
                { text: formatCurrency(invoice.total_discount_amount), alignment: 'right' },
                { text: formatCurrency(invoice.total_offer_discount), alignment: 'right' },
                { text: formatCurrency(invoice.vat_amount), alignment: 'right' },
            ]);

            return doc.content[3].table.body.push(data);
        }

        if (hasTradeDiscount || hasOfferDiscount || hasVatAmount) {
            data.splice(5, 0 , { text: formatCurrency(invoice.total_amount), alignment: 'right' })
        }

        if (hasVatAmount) {
            data.splice(5, 0 , { text: formatCurrency(invoice.vat_amount), alignment: 'right' })
        }

        if (hasOfferDiscount) {
            data.splice(5, 0 , { text: formatCurrency(invoice.total_offer_discount), alignment: 'right' })
        }

        if (hasTradeDiscount) {
            data.splice(5, 0 , { text: formatCurrency(invoice.total_discount_amount), alignment: 'right' })
        }

        return doc.content[3].table.body.push(data);

    }

    const getTableHeader = (btnFunc, tableItems) => {
        if (btnFunc != 1) {
            return [
                { text: 'PARTICULARS' },
                { text: 'UNIT', alignment: 'left' },
                { text: 'DELIVERY QTY', alignment: 'right' },
                { text: 'REMARKS' }
            ]
        }

        const hasFreeQuantity = tableItems.find(item => item.free_quantity > 0);
        const hasTradeDiscount = tableItems.find(item => item.discount_amount > 0);
        const hasOfferDiscount = tableItems.find(item => item.offer_discount > 0);
        const hasVatAmount = tableItems.find(item => item.vat_amount > 0);

        const headingRow = [
            { text: 'SL #' },
            { text: 'PARTICULARS' },
            { text: 'QTY' },
            { text: 'RATE' },
            { text: 'AMOUNT', alignment: 'right' },
        ]

        if (!! hasFreeQuantity) {
            headingRow.push({ text: 'BONUS QTY', alignment: 'right' });
        }

        if (!! hasTradeDiscount && !! hasOfferDiscount && !! hasVatAmount) {
            headingRow.splice(5, 0, ...[
                { text: 'TOTAL AMOUNT', alignment: 'right' },
                { text: 'TRADE DISCOUNT', alignment: 'right' },
                { text: 'OFFER DISCOUNT', alignment: 'right' },
                { text: 'VAT AMOUNT', alignment: 'right' },
            ]);
            return headingRow
        }

        if (!!hasTradeDiscount || !!hasOfferDiscount || !!hasVatAmount) {
            headingRow.splice(5, 0 , { text: 'TOTAL AMOUNT', alignment: 'right' })
        }

        if (!! hasVatAmount) {
            headingRow.splice(5, 0 , { text: 'VAT AMOUNT', alignment: 'right' })
        }

        if (!! hasOfferDiscount) {
            headingRow.splice(5, 0 , { text: 'OFFER DISCOUNT', alignment: 'right' })
        }

        if (!! hasTradeDiscount) {
            headingRow.splice(5, 0 , { text: 'TRADE DISCOUNT', alignment: 'right' })
        }


        return headingRow;
    }
    const getRowData = (invoice, item, btnFunc, hasFreeQuantity, hasTradeDiscount, hasOfferDiscount, hasVatAmount) => {
        srNumber += 1;
        if (btnFunc != 1) {
            return [
                { text: (invoice.has_item_detail ? item.product.name : item.head.name) + '\n' + (item.description ? item.description : '') },
                { text: item.product ? item.product.description.unit.name : '' },
                { text: formatCurrency(item.quantity + item.free_quantity), alignment: 'right' },
                { text: '' }
            ]
        }

        const data = [
            { text:  srNumber },
            {
                text: (invoice.has_item_detail ? item.product.name : item.head.name) + '\n' +
                  (item.description ? item.description : '') + '\n' +
                  (['initial','surveillance'].includes(item.sale_order_type) ?
                      toCapitalizeFirst(item.sale_order_type) : ''
                  )
            },
            { text:  invoice.has_item_detail ? formatCurrency(item.quantity) : '', alignment: 'right' },
            { text: invoice.has_item_detail ? formatCurrency(item.rate) : '', alignment: 'right' },
            {
                text: invoice.has_item_detail ?
                        formatCurrency(item.quantity * item.rate) :
                        formatCurrency(item.amount),
                alignment: 'right'
            },
        ]

        if (hasFreeQuantity) {
            data.push({ text:  invoice.has_item_detail ? formatCurrency(item.free_quantity) : '', alignment: 'right' })
        }

        if (hasTradeDiscount && hasOfferDiscount && hasVatAmount) {
            data.splice(5, 0, ...[
                { text:  formatCurrency(item.total_amount), alignment: 'right' },
                { text:  invoice.has_item_detail ? formatCurrency(item.discount_amount) : '', alignment: 'right' },
                { text:  invoice.has_item_detail ? formatCurrency(item.offer_discount) : '', alignment: 'right' },
                { text: formatCurrency(item.vat_amount), alignment: 'right' },
            ]);

            return data
        }

        if (hasTradeDiscount || hasOfferDiscount || hasVatAmount) {
            data.splice(5, 0 , { text:  formatCurrency(item.total_amount), alignment: 'right' })
        }

        if (hasVatAmount) {
            data.splice(5, 0 , { text: formatCurrency(item.vat_amount), alignment: 'right' })
        }

        if (hasOfferDiscount) {
            data.splice(5, 0 , { text:  invoice.has_item_detail ? formatCurrency(item.offer_discount) : '', alignment: 'right' })
        }

        if (hasTradeDiscount) {
            data.splice(5, 0 , { text:  formatCurrency(item.discount_amount), alignment: 'right' })
        }


        return data;
    }

    return {
        exportToPDF
    }
}

export default pdfPrinter;
